<template>
  <v-footer
    dark
    height="auto"
    padless
  >
    <v-row class="ma-0">
      <v-col
        class="pa-5 grey lighten-4"
        cols="12"
        md="3"
      >
    <v-img
          alt="Feil Counseling"
          class="shrink"
          :max-width="$vuetify.breakpoint.mdAndUp ? '75%' : '325' "
          src="/static/feil-logo.png"
        />
      <!-- <h3 class="black--text ml-4">Foothills Bible Church</h3> -->
      <p class="black--text pa-0 mb-0 ml-4">6112 S Devinney Way</p>
      <p class="black--text pa-0 ml-4">Littleton, CO 80127</p>
      </v-col>
      <v-col
        class="pa-5"
        cols="12"
        md="9"
      >
        <v-row>
          <v-col
            v-for="(category, i) in categories"
            :key="i"
            cols="12"
            md="6"
          >
            <a
              :href="category.link"
              v-text="category.title"
              class="white--text"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="text-center blue-grey darken-4 pa-3"
        cols="12"
      >
        &copy; 2020 Feil Counseling — All Rights reserved.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      categories: [
        {
          title: 'Individual',
          link: `https://feilcounselingandconsulting.com/counseling`
        },
        {
          title: `Marriage`,
          link: `https://feilcounselingandconsulting.com/counseling`
        },
        {
          title: `Family`,
          link: `https://feilcounselingandconsulting.com/counseling`
        },
        {
          title: `Trauma`,
          link: `https://feilcounselingandconsulting.com/counseling`
        },
        {
          title: `Missionary Counseling`,
          link: `https://feilcounselingandconsulting.com/ministry`
        },
        {
          title: `Consulting`,
          link: `https://feilcounselingandconsulting.com/consulting`
        },
        {
          title: `About Feil Counseling`,
          link: `https://feilcounselingandconsulting.com/about`
        },
        {
          title: `Contact Feil Counseling`,
          link: `https://feilcounselingandconsulting.com/contact`
        },
      ],
      company: [
        'Services',
        'Projects',
        'Our Team',
        'Commitment to the Community'
      ]
    })
  }
</script>

<style lang="sass">
  .v-application .v-footer
    ul
      list-style-type: none

    a
      text-decoration: none

      &:hover
        color: grey
</style>
